body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
  
    font-family: -apple-system, BlinkMacSystemFont, "Libre Franklin", "Roboto", "Lato", "Ariel" sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerWrapper h2 {
  margin-top: 20px;
}

.scheduleText {
  text-align: center;
  font-size: 1.3em;
  width: 70%;
  height: auto;
}

.eligibilityText {
  text-align: left;
  font-size: 1.3em;
  width: 70%;
  height: auto;
}

.editorial {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: auto; 
}

.editorial img{
  width: 50%;
  height: auto; 
}


.scheduleWrapper {
  min-width: 90%;
  height: 630px;
}
.scheduleWrapper iframe {
  width: 90%;
}

.resultsHeaderWrapper {
  text-align: center;
}

.resultsHeaderWrapper img {
  width: 250px;
}

.masterclassWrapper {

  align-items: center;
}

.masterclassWrapper img{
  width: 90%;
  height: auto;
}

.masterclassWrapper .row {
  border-bottom: 3px solid gray;
  padding: 20px;
  text-align: center;
  align-items: center;
}

.masterclassWrapper button,
.scheduleButton {
  background-color: rgb(249, 57, 130);
  color: white;
  border: rgb(249, 57, 130);
  padding: 10px 25px;
  border-radius: 24px;
  font-size: 1.2em;
  font-weight: bold;
  border-bottom-color: rgb(43,104,156);
  box-shadow: 0px 3px 0px 0px rgba(86,111,184,0.6);
  align-items:center;
}

.lenderCategories .row {
  border-bottom: 3px solid gray;
  padding: 20px;
  text-align: center;
}

.lenderUserName {
  text-align: center;
  padding: 20px;
  width: 70%;
  color: rgb(52,76,115);
}

@media screen and (max-width: 488px) {
  .lenderUserName {
    width: 95%;
  }
}

.lenderCategories img {
  width: 100%;
}

.infoText {
  color: rgb(249, 57, 130);
  font-weight: bold;
}

.loadingText {
  color: rgb(249, 57, 130);
  font-weight: bold;
  font-size: 2.5em;
  text-align: center;
  padding: 20px;
}

.dataText {
  font-size: 2em;
}

.lenderCategories button,
.scheduleButton {
  background-color: rgb(249, 57, 130);
  color: white;
  border: rgb(249, 57, 130);
  padding: 10px 25px;
  border-radius: 24px;
  font-size: 1.2em;
  font-weight: bold;
  border-bottom-color: rgb(43,104,156);
  box-shadow: 0px 3px 0px 0px rgba(86,111,184,0.6);
  align-items:center;
}

.scheduleWrapper {
  display: flex;
  justify-content: center;
}

.pressSection {
  background: white;
  margin-top: -5px;
  padding: 50px 10px;
}

.pressSection h4 {
  color: rgb(52,76,115);
  font-style: italic;
  text-align: center;
  padding: 30px;
}

.pressSection img {
  width: 100%;
}

@media screen and (max-width: 488px) {
  .pressSection div {
    display: flex;
    justify-content: center;
  }
}

.aboutWrapper {
  background: rgb(52,76,115);
  color: white;
  padding: 50px 150px;
}

.aboutWrapper h2 {
  font-size: 3em;
}

.aboutWrapper h2,
.aboutWrapper h5 {
  text-align: center;
  padding: 20px;
}

.aboutWrapper h4 {
  color: rgb(253, 171, 192);
  font-style: italic;
  text-align: center;
  padding: 0px 0px 20px 0px;
}

/* Fixing Text Color to New Blue */
.aboutWrapper .aboutMinorText {
  color: rgb(52,76,115);
}

.highlightedText {
  color: rgb(249, 57, 130);
}

.aboutWrapper a {
  color: rgb(249, 57, 130);
}

@media screen and (max-width: 488px) {
  .aboutWrapper {
    background: rgb(52,76,115);
    color: white;
    padding: 50px 20px;
    margin-top: -5px;
  }
}
